//node_modules
import React, { FunctionComponent, useCallback, useEffect, useRef } from "react";
import { Button, Container, Carousel, ButtonGroup } from "react-bootstrap";
import Link from "next/link";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBackward, faForward } from "@fortawesome/free-solid-svg-icons";
import type { CarouselRef } from "react-bootstrap/esm/Carousel";

//libs
import type { TestimonialDocument } from "@adjust/libs/database/testimonials";

//components
import StarRatingComponent from "src/components/star-rating-component";
import PageHeaderComponent from "src/components/PageHeaderComponent";

//styles
import { homeTestimonialCarouselItemCSS } from "@adjust/website/src/styles/modules/shared";

interface TestimonialCarouselProps {
  testimonials: TestimonialDocument[];
}

const TestimonialCarouselComponent: FunctionComponent<TestimonialCarouselProps> = ({ testimonials }) => {
  const ref = useRef<CarouselRef>(null);

  const gotoNext = useCallback(() => ref.current?.next(), [ref]);
  const gotoPrev = useCallback(() => ref.current?.prev(), [ref]);

  return (
    <React.Fragment>
      <Container className="mt-5">
        <PageHeaderComponent type="h2" className="text-center">
          Our Customer Testimonials
        </PageHeaderComponent>
      </Container>
      <div className="my-5">
        <Carousel ref={ref} slide={true} wrap={true} controls={false} indicators={false} interval={5000}>
          {testimonials.map((testimonial, i) => (
            <Carousel.Item key={i} className="text-center">
              <div className={homeTestimonialCarouselItemCSS}>
                <p className="h3 mt-3">{testimonial.title}</p>
                <p className="h5">{testimonial.locations}</p>
                <StarRatingComponent rating={testimonial.rating} />
                <p>{testimonial.description}</p>
                <p>{`— ${testimonial.reviewer}`}</p>
              </div>
            </Carousel.Item>
          ))}
        </Carousel>
        <div className="d-flex justify-content-center">
          <ButtonGroup>
            <Button variant="outline-dark" onClick={gotoPrev}>
              <FontAwesomeIcon icon={faBackward} />
              <span className="ms-2">Prev</span>
            </Button>
            <Link href="/testimonials/"  legacyBehavior passHref>
              <Button variant="outline-primary">Read More Reviews</Button>
            </Link>
            <Button variant="outline-dark" onClick={gotoNext}>
              <span className="me-2">Next</span>
              <FontAwesomeIcon icon={faForward} />
            </Button>
          </ButtonGroup>
        </div>
      </div>
    </React.Fragment>
  );
};

export default React.memo(TestimonialCarouselComponent);
