//node_modules
import { useMemo, FunctionComponent, useCallback,Fragment } from "react";
import { AppProps, NextWebVitalsMetric } from "next/app";
import { EJSON } from "bson";
import { useEffectOnce } from "react-use";
import { CacheProvider } from "@emotion/react";
import { event as googleEvent } from "nextjs-google-analytics";
import Head from "next/head";

//components
import ErrorPage from "src/components/error-page";
import LoadingBarComponent from "src/components/loading-bar-component";

//libs
import { cache } from "@adjust/libs/emotion";
import secretFunction from "@adjust/libs/secret";

//styles
import "src/styles/global/app.scss";
import "bootstrap/dist/css/bootstrap.min.css";

//configs
import { config } from "@fortawesome/fontawesome-svg-core";
import "@fortawesome/fontawesome-svg-core/styles.css";
import { useRouter } from "next/router";

config.autoAddCss = false;

const useCreateMainComponent = (Component: AppProps["Component"]) =>
  useCallback<FunctionComponent<any>>(
    (props) => {
      if (props.error) return <ErrorPage {...props.error} />;
      return <Component {...props} />;
    },
    [Component]
  );

const MyApp = ({ Component, pageProps }: AppProps) => {
  const props = useMemo<any>(() => EJSON.deserialize(pageProps), [pageProps]);
  useEffectOnce(secretFunction);

  const MainComponent = useCreateMainComponent(Component);

  const router = useRouter();
  router.prefetch = async () => {};

  return (
    <Fragment>
      <Head>
        <link rel="apple-touch-icon" sizes="180x180" href="/favicon/apple-touch-icon.png" />
        <link rel="icon" type="image/png" sizes="32x32" href="/favicon/favicon-32x32.png" />
        <link rel="icon" type="image/png" sizes="16x16" href="/favicon/favicon-16x16.png" />
        <link rel="manifest" href="/site.webmanifest" />
        <link rel="mask-icon" href="/favicon/safari-pinned-tab.svg" color="#5bbad5" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta name="msapplication-TileColor" content="#da532c" />
        <meta name="theme-color" content="#317EFB" />
      </Head>
      <CacheProvider value={cache}>
        <LoadingBarComponent />
        <MainComponent {...props} />
      </CacheProvider>
    </Fragment>
  );
};

function getCategory(label: string) {
  if (label === "web-vital") return "Web Vitals";
  return "Next.js Custom Metric";
}

function getValue(name: string, value: number) {
  if (name === "CLS") {
    return Math.round(value * 1000);
  }
  return Math.round(value);
}

export function reportWebVitals({ id, name, label, value }: NextWebVitalsMetric) {
  googleEvent(name, {
    category: getCategory(label),
    value: getValue(name, value), // values must be integers
    label: id, // id unique to current page load
    nonInteraction: true, // avoids affecting bounce rate.
  });
}

export default MyApp;
