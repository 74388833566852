import { FunctionComponent } from "react";
import { ErrorResponseResult } from "@adjust/fetcher/errors";
import Image from "next/image";
import { Card } from "react-bootstrap";

//layout
import SiteLayout from "src/layouts/SiteLayout";

//libs
import { css, cx } from "@adjust/libs/emotion";
import getBootstrapSizes from "libs/helper/get-bootstrap-sizes";

//image
import background from "@adjust/website/src/images/1d4077e508a79c7a7ef3713ccd35e6ed.png";

const minSizeCSS = css({
  minHeight: "256px",
});

const imageCSS = css({
  width: "100%",
  height: "auto",
  objectFit: "cover",
});

const header = css({
  fontSize: "64px",
  fontWeight: "bold",
  color: "white",
  textAlign: "center",
  textShadow: "1px 1px 2px rgba(192, 192, 192, 0.3)",
  fontFamily: "Montserrat, sans-serif",
});

const subheader = css({
  fontSize: "36px",
  fontWeight: "normal",
  color: "white",
  textAlign: "center",
  textShadow: "1px 1px 2px rgba(192, 192, 192, 0.3)",
  fontFamily: "Montserrat, sans-serif",
});

const linkColorCSS = css({
  color: "white",
});

const linkCSS = css({
  fontSize: "1em",
  textShadow: "1px 1px 0px rgba(0, 0, 0, 0.6)",
  textAlign: "center",
});

const sizes = getBootstrapSizes({ xs: 256, sm: 640, md: 750, lg: 1080 });

const ErrorPage: FunctionComponent<ErrorResponseResult> = (p) => {
  return (
    <SiteLayout>
      <Card className={cx(minSizeCSS, "border-0", "col", "d-flex", "flex-column")}>
        <Image src={background} alt="Photo by Dino Reichmuth" className={imageCSS} sizes={sizes} quality={80} priority fill />
        <Card.ImgOverlay className="d-flex flex-column p-0">
          <div className="d-flex flex-column my-auto">
            <h1 className={cx(header, "display-1", "text-center", "font-weight-bold")}>{p.status || 404}</h1>
            <h4 className={cx(subheader, "text-center")}>{p.message || "Page not Found!"}</h4>
          </div>
          <div className={cx(linkCSS, linkColorCSS, "mt-auto")}>
            Photo by{" "}
            <a className={linkColorCSS} target="_blank" href="https://unsplash.com/@lexmelony?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">
              Lex Melony
            </a>{" "}
            on{" "}
            <a className={linkColorCSS} target="_blank" href="https://unsplash.com/photos/R5WSLOYauCg?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">
              Unsplash
            </a>
          </div>
        </Card.ImgOverlay>
      </Card>
    </SiteLayout>
  );
};

export default ErrorPage;
