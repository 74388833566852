import React, { FunctionComponent } from "react";
import Link from "next/link";
import { css, cx } from "@adjust/libs/emotion";
import { Row, Col, Card, Container } from "react-bootstrap";

//constants
import { FACEBOOK_LINK, INSTAGRAM_LINK } from "libs/constants";
import { BREAKPOINTS } from "@adjust/website/src/styles/modules/shared";

const container = css({
  color: "white",
  backgroundColor: "#161616",
  [BREAKPOINTS.xs]: {
    padding: "1em 0",
  },
  [BREAKPOINTS.md]: {
    padding: "2em 0",
  },
});

const foothold = css({
  color: "#b8b8b8",
  fontSize: "0.8em",
  backgroundColor: "#262626",
  padding: "12px 0",
});

const specialLink = css({
  color: "white",
  textDecoration: "none",
  transition: "color 0.15s",
  [":hover"]: {
    color: "#6b52f8",
  },
});

const heading = css({
  color: "rgba(255, 255, 255, 0.7)",
  fontSize: "1.2em",
  padding: "6px 0",
  letterSpacing: "0.1em",
});

const linkWrapper = css({
  padding: "3px 0",
});

const link = css({
  color: "white",
  textDecoration: "none",
  transition: "color 0.15s",
  [":hover"]: {
    color: "#6b52f8",
  },
});

//TODO add destinations back when it actually is live!
const exploreLinks = [
  {
    href: "/blogs/",
    text: "Read our Blogs",
  },
  // {
  //   href: "/destinations/",
  //   text: "Destinations",
  // },
  {
    href: "/press/",
    text: "Press Mentions",
  },
  {
    href: "/privacy/",
    text: "Privacy Notice",
  },
];

const discoverLinks = [
  {
    href: "/sandals-beaches-resorts/",
    text: "Sandals / Beaches",
  },
  {
    href: "/services/",
    text: "Services we Provide",
  },
  {
    href: "/testimonials/",
    text: "Our Testimonials",
  },
  {
    href: "/faq/",
    text: "Frequently asked Questions",
  },
];

const socialLinks = [
  {
    href: FACEBOOK_LINK,
    text: "Facebook",
  },
  {
    href: INSTAGRAM_LINK,
    text: "Instagram",
  },
  // {
  //   href: "/#", //TODO get pinterest href
  //   text: "Pinterest",
  // },
];

const contactLinks = [
  {
    href: "tel:+1-919-810-3743",
    text: "(919) 810-3743",
  },
  {
    href: "mailto:contact@adjusttothegoodlifetravel.com",
    text: "Send an Email",
  },
  {
    href: "/contact/",
    text: "Send a Message",
  },
];

const FooterComponent: FunctionComponent = () => {
  return (
    <footer id="footer" itemType="https://schema.org/WPFooter" itemScope>
      <div className={container}>
        <Container>
          <Row className="justify-content-center">
            <Col className="py-3" xs={12} md={6} lg={3}>
              <Card.Body className="text-center">
                <Card.Title className={heading}>Explore</Card.Title>
                {exploreLinks.map((data) => (
                  <div key={data.text} className={linkWrapper}>
                    <Link href={data.href} className={link}>
                      {data.text}
                    </Link>
                  </div>
                ))}
              </Card.Body>
            </Col>
            <Col className="py-3" xs={12} md={6} lg={3}>
              <Card.Body className="text-center">
                <Card.Title className={heading}>Discover</Card.Title>
                {discoverLinks.map((data) => (
                  <div key={data.text} className={linkWrapper}>
                    <Link href={data.href} className={link}>
                      {data.text}
                    </Link>
                  </div>
                ))}
              </Card.Body>
            </Col>
            <Col className="py-3" xs={12} md={6} lg={3}>
              <Card.Body className="text-center">
                <Card.Title className={heading}>Contact</Card.Title>
                {contactLinks.map((data) => (
                  <div key={data.text} className={linkWrapper}>
                    <Link href={data.href} className={link}>
                      {data.text}
                    </Link>
                  </div>
                ))}
              </Card.Body>
            </Col>
            <Col className="py-3" xs={12} md={6} lg={3}>
              <Card.Body className="text-center">
                <Card.Title className={heading}>Socials</Card.Title>
                {socialLinks.map((data) => (
                  <div key={data.text} className={linkWrapper}>
                    <Link href={data.href} className={link} rel="noopener nofollow" target="_blank">
                      {data.text}
                    </Link>
                  </div>
                ))}
              </Card.Body>
            </Col>
          </Row>
        </Container>
      </div>
      <div className={cx(foothold, "text-center")}>
        <div>
          © 2013 Adjust to the Good Life Travel • Independent Agents of{" "}
          <Link href="https://khmtravel.com/" className={specialLink} rel="noopener nofollow" target="_blank">
            KHM Travel Group
          </Link>{" "}
          and Proud Member of IATAN, and{" "}
          <Link href="https://www.vacation.com" className={specialLink} rel="noopener nofollow" target="_blank">
            Vacation.com
          </Link>
        </div>
        <div>Fla. Seller of Travel Reg. No. ST37113 • California Seller of Travel Registration Number: 2089491-50</div>
      </div>
    </footer>
  );
};

export default React.memo(FooterComponent);
