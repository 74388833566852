//node_modules
import React, { FunctionComponent, PropsWithChildren } from "react";
import { GoogleAnalytics } from "nextjs-google-analytics";
import {If, Then} from 'react-if'
import getConfig from "next/config";

//src constants
import HeaderComponent from "src/components/header-component";
import FooterComponent from "src/components/footer-component";

const { publicRuntimeConfig } = getConfig();

const enabled = process.env.NODE_ENV === "production";

const SiteLayout: FunctionComponent<PropsWithChildren<{}>> = ({ children }) => {
  return (
    <React.Fragment>
      <If condition={enabled}>
        <Then>
        <GoogleAnalytics gaMeasurementId={publicRuntimeConfig.GA_MEASUREMENT_ID} trackPageViews />
        </Then>
      </If>
      <HeaderComponent />
      {children}
      <div className="my-auto" />
      <FooterComponent />
    </React.Fragment>
  );
};

export default SiteLayout;
