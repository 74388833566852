import { FunctionComponent, PropsWithChildren } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { css } from "@adjust/libs/emotion";
import { BREAKPOINTS } from "src/styles/modules/shared";

type SizeType = "sm" | "md";

const heightSmallCSS = css({
  minHeight: "200px",
  [BREAKPOINTS.sm]: {
    minHeight: "298px",
  },
  [BREAKPOINTS.lg]: {
    minHeight: "398px",
  },
});

const heightMediumCSS = css({
  minHeight: "298px",
  [BREAKPOINTS.sm]: {
    minHeight: "447px",
  },
  [BREAKPOINTS.lg]: {
    minHeight: "596px",
  },
});

const textCSS = css({
  color: "white",
  fontSize: "2.3em",
  fontFamily: "Domine, sans-serif",
  textAlign: "center",
  textShadow: "2px 2px black",
  [BREAKPOINTS.sm]: {
    fontSize: "2.5em",
  },
  [BREAKPOINTS.lg]: {
    fontSize: "3em",
  },
});

function getRowClass(size?: SizeType) {
  switch (size) {
    case "sm":
      return heightSmallCSS;
    default:
    case "md":
      return heightMediumCSS;
  }
}

interface PropsType {
  size?: SizeType;
}

const ParallaxHeaderComponent: FunctionComponent<PropsWithChildren<PropsType>> = ({ size, children }) => {
  return (
    <Container className="my-5">
      <Row className={`g-0 justify-content-center ${getRowClass(size)}`}>
        <Col className="my-auto d-flex justify-content-center">
          <h1 className={textCSS}>{children}</h1>
        </Col>
      </Row>
    </Container>
  );
};

export default ParallaxHeaderComponent;
