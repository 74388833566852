//node_modules
import { FunctionComponent, PropsWithChildren } from "react";

//emotion
import { cx, css } from "@adjust/libs/emotion";

const headerCSS = css({
  fontFamily: "Domine, sans-serif",
  backgroundColor: "#e6f0ff",
  border: "1px solid #d7e7ff",
  padding: "12px",
  borderRadius: "20px",
});

interface PageHeaderProps {
  type: "h1" | "h2" | "h3" | "p";
  className?: string;
}

const PageHeaderComponent: FunctionComponent<PropsWithChildren<PageHeaderProps>> = ({ type, className, children }) => {
  const Component = type;
  return <Component className={cx(headerCSS, className)}>{children}</Component>;
};

export default PageHeaderComponent;
