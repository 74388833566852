import { IconDefinition, faStar as faSolidStar, faStarHalfAlt } from "@fortawesome/free-solid-svg-icons";
import { faStar as faRegularStar } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { FunctionComponent } from "react";

type StarRatingProps = {
  rating: number;
};

const NONE = faRegularStar;
const HALF = faStarHalfAlt;
const FULL = faSolidStar;

const ratings: [IconDefinition, IconDefinition, IconDefinition, IconDefinition, IconDefinition][] = [];
ratings[0] = [NONE, NONE, NONE, NONE, NONE];
ratings[1] = [HALF, NONE, NONE, NONE, NONE];
ratings[2] = [FULL, NONE, NONE, NONE, NONE];
ratings[3] = [FULL, HALF, NONE, NONE, NONE];
ratings[4] = [FULL, FULL, NONE, NONE, NONE];
ratings[5] = [FULL, FULL, HALF, NONE, NONE];
ratings[6] = [FULL, FULL, FULL, NONE, NONE];
ratings[7] = [FULL, FULL, FULL, HALF, NONE];
ratings[8] = [FULL, FULL, FULL, FULL, NONE];
ratings[9] = [FULL, FULL, FULL, FULL, HALF];
ratings[10] = [FULL, FULL, FULL, FULL, FULL];

function getRating(rating: number) {
  if (rating > 10) rating = 10;
  if (rating < 0) rating = 0;
  return ratings[rating];
}

const StarRatingComponent: FunctionComponent<StarRatingProps> = ({ rating }) => {
  const list = getRating(rating);
  return (
    <div>
      {list.map((icon, index) => (
        <FontAwesomeIcon key={index} icon={icon} color="orange" />
      ))}
    </div>
  );
};

export default React.memo(StarRatingComponent);
