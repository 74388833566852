import { css } from "@adjust/libs/emotion";

export { cx } from "@adjust/libs/emotion";

export enum BREAKPOINTS {
  xs = "@media (min-width: 0px)",
  sm = "@media (min-width: 576px)",
  md = "@media (min-width: 768px)",
  lg = "@media (min-width: 992px)",
  xl = "@media (min-width: 1200px)",
  xxl = "@media (min-width: 1400px)",
}

export const minHeight594CSS = css({
  minHeight: "594px",
});

export const width18emCSS = css({
  width: "18em",
});

export const modifiedTextCSS = css({
  fontSize: "1.125em",
  fontFamily: "DM Sans, sans-serif",
});

export const whiteBgAltCSS = css({
  backgroundColor: "white",
});

export const homePartnersCSS = css({
  minWidth: "128px",
  minHeight: "128px",
  maxWidth: "156px",
  maxHeight: "156px",
});

export const homeTestimonialCarouselItemCSS = css({
  minHeight: "384px",
  width: "100%",
  maxWidth: "640px",
  fontSize: "1em",
  margin: "auto",
});

//about page
export const imageContainerCSS = css({
  position: "relative",
  width: "100%",
  margin: "auto",
  maxWidth: "320px",
  "> span": {
    borderRadius: "25px",
  },
});

//faq page
export const faqQuestionHeaderCSS = css({
  fontFamily: "DM Sans, sans-serif",
  textAlign: "center",
  [BREAKPOINTS.md]: {
    textAlign: "left",
  },
});

export const faqQuestionTextCSS = css({
  fontFamily: "lato, sans-serif",
  fontSize: "1.2em",
  textAlign: "center",
  [BREAKPOINTS.md]: {
    textAlign: "justify",
  },
});
