//node_modules
import React, { Fragment, FunctionComponent, useState } from "react";
import NextImage from "next/legacy/image";
import dynamic from "next/dynamic";
import { faCalendarAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Card, Container, Row, Col, Button } from "react-bootstrap";
import { PopupModal } from "react-calendly";

//components
import PageHeaderComponent from "src/components/PageHeaderComponent";

//images
import image1 from "src/images/8df5c93fd190e56f46108d39ed8bbb51.png";
import image2 from "src/images/3310862be57917f6970e00c2086ab4cb.png";
import image3 from "src/images/d5b944c1b1af0e7f461be0448084c5a8.png";
import image4 from "src/images/d9aa28303e629eb1d172dc829b68caff.png";

//styles
import { cx, modifiedTextCSS } from "@adjust/website/src/styles/modules/shared";

const data = [
  {
    title: "Creating It",
    description:
      "Set up a complimentary consultation with us through email or phone. We will gather all the details, and then get creative and piece together the perfect itinerary.",
    src: image1,
    alt: "Picture of family",
    quality: 50,
  },
  {
    title: "Perfecting It",
    description: "Working together, we can then fine tune all the details and tailor the experiences to your desires until it is perfect.",
    src: image2,
    alt: "Picture of a sailboats",
    quality: 50,
  },
  {
    title: "Payment",
    description: "Once you are happy, you will make a deposit to secure the trip you have always dreamed of and wanted to experience!",
    src: image3,
    alt: "Picture of birds",
    quality: 50,
  },
  {
    title: "Enjoy!",
    description: "While you are away on your amazing unforgettable vacation, we will be available to help with any questions that may arise.",
    src: image4,
    alt: "Vase full of flowers",
    quality: 50,
  },
];

import getBootstrapSizes from "libs/helper/get-bootstrap-sizes";

const sizes = getBootstrapSizes({ xs: 384, xl: 256 });

const CalendlyComponent = dynamic(() => import("./CalendlyComponent"), { ssr: false });

const TravelPlanComponent: FunctionComponent = () => {
  const [isCalendlyOpen, setCalendlyOpen] = useState(false);

  return (
    <Fragment>
      <Container className="my-3">
        <PageHeaderComponent type="h2" className="text-center">
          Ready to get Started?
        </PageHeaderComponent>
        <Row>
          {data.map((info) => {
            return (
              <Col key={info.title} sm={12} md={6} xl={3}>
                <Card className="h-100 border-0">
                  <Card.Body className="d-flex flex-column text-center">
                    <Card.Title as="h3">{info.title}</Card.Title>
                    <Card.Text className={modifiedTextCSS}>{info.description}</Card.Text>
                    <div className="py-2 my-auto" />
                    <div>
                      {/*@ts-ignore*/}
                      <NextImage src={info.src} alt={info.alt} placeholder="blur" width="100%" height="100%" sizes={sizes} quality={info.quality} layout="responsive" />
                    </div>
                  </Card.Body>
                </Card>
              </Col>
            );
          })}
        </Row>
        <Row className="mt-5">
          <PageHeaderComponent type="h2" className="text-center">
            Dream Vacation Planning Session
          </PageHeaderComponent>
          <p className={cx(modifiedTextCSS, "text-center")}>
            Let's schedule your FREE 15-minute travel consultation and speak by phone. I can answer your travel questions and collect the information I need to turn your travel
            dreams into a memorable vacation!
          </p>
          <div className="text-center">
            <Button size="lg" onClick={() => setCalendlyOpen(true)}>
              <FontAwesomeIcon icon={faCalendarAlt} />
              <span className="ms-2">BOOK YOUR FREE CONSULTATION</span>
            </Button>
          </div>
        </Row>
      </Container>
      <CalendlyComponent open={isCalendlyOpen} onClose={() => setCalendlyOpen(false)} />
    </Fragment>
  );
};

export default React.memo(TravelPlanComponent);
