//node_modules
import React from "react";
import { NextPage } from "next";
import Link from "next/link";
import { Container, Row, Col, Card, Button } from "react-bootstrap";

//types
import type { PageProps } from "./types";

//layout
import SiteLayout from "src/layouts/SiteLayout";

//components
import ParallaxComponent from "src/components/parallax-component";
import ParallaxHeaderComponent from "src/components/parallax-header-component";
import PageHeaderComponent from "src/components/PageHeaderComponent";

//locals
import StatementComponent from "./components/StatementComponent";
import TravelPlanComponent from "./components/TravelPlanComponent";
import TestimonialCarouselComponent from "./components/TestimonialCarouselComponent";
import PartnersAffiliatesComponent from "./components/PartnersAffiliatesComponent";
import MetaDataComponent from "./components/MetaDataComponent";

//images
import parallax1 from "src/images/5f7cbe2dc628e35784b204b6d16dba89.png";
import parallax2 from "src/images/f8f8e0309fcf757da2220e09081f9e54.png";

//styles
import { cx, minHeight594CSS, width18emCSS } from "@adjust/website/src/styles/modules/shared";

const HomePage: NextPage<PageProps> = ({ testimonials }) => {
  return (
    <SiteLayout>
      <MetaDataComponent />
      <ParallaxComponent bgImage={parallax1} bgImageAlt="Mountains" strength={-300} priority>
        <ParallaxHeaderComponent>
          Adjust to the Good Life Travel is a Raleigh, North Carolina based travel agency, where we specialize in turning your travel dreams into memorable vacations!
        </ParallaxHeaderComponent>
      </ParallaxComponent>
      <StatementComponent />
      <TravelPlanComponent />
      <ParallaxComponent bgImage={parallax2} bgImageAlt="Oceans" strength={-300}>
        <Container className="my-5">
          <Row className={cx(minHeight594CSS, "g-0", "text-center")}>
            <Col md={6} className="my-auto d-flex">
              <Card className={cx(width18emCSS, "mx-auto", "border-dark")}>
                <Card.Body>
                  <Card.Title>
                    <PageHeaderComponent type="p" className="text-center">
                      Ready to get started?
                    </PageHeaderComponent>
                  </Card.Title>
                  <Card.Text>
                    Let my travel experience help to plan <b>your vacation!</b>
                  </Card.Text>
                  <Row className="g-0">
                    <Link href="/contact"  legacyBehavior passHref>
                      <Button variant="outline-primary">CONTACT US</Button>
                    </Link>
                  </Row>
                </Card.Body>
              </Card>
            </Col>
            <Col md={6} className="my-auto d-flex">
              <Card className={cx(width18emCSS, "mx-auto", "border-dark")}>
                <Card.Body>
                  <Card.Title>
                    <PageHeaderComponent type="p" className="text-center">
                      Sandals Resorts
                    </PageHeaderComponent>
                  </Card.Title>
                  <Card.Text>
                    <b>More quality inclusions</b> than any other resorts on the planet
                  </Card.Text>
                  <Row className="g-0">
                    <Link href="/sandals-beaches-resorts"  legacyBehavior passHref>
                      <Button variant="outline-primary">LOOK CLOSER</Button>
                    </Link>
                  </Row>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </ParallaxComponent>
      <TestimonialCarouselComponent testimonials={testimonials} />
      <PartnersAffiliatesComponent />
    </SiteLayout>
  );
};

export default HomePage;
